import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Modelo2 from "./components/Modelo2";
import Modelo1 from "./components/Modelo1";
import Modelo3 from "./components/Modelo3";
import Modelo0 from "./components/Modelo0";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Model0" component={Modelo0} />
        <Route exact path="/Model1" component={Modelo1} />
        <Route exact path="/Model2" component={Modelo2} />
        <Route exact path="/Model3" component={Modelo3} />
      </Switch>
    </BrowserRouter>
  );
}
