import React, { useRef, useEffect, Suspense, useState, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import { LoopOnce } from "three";
import { Loop } from "@mui/icons-material";
import { Button } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/modelo1.glb");
  const { actions } = useAnimations(animations, group);

  const [visibleMolde, setVisibleMolde] = useState(false);

  console.log(actions);

  useEffect(() => {
    if (props.sapatoNormal) {
      playSapatoNormal();
    }
  }, [props.sapatoNormal]);

  useEffect(() => {
    if (props.sapatoExplode) {
      playSapatoExplode();
    }
  }, [props.sapatoExplode]);

  useEffect(() => {
    if (props.fecharMolde) {
      playAnimFecharMolde();
    }
  }, [props.fecharMolde]);

  useEffect(() => {
    if (props.abrirMolde) {
      playAnimAbrirMolde();
    }
  }, [props.abrirMolde]);

  useFrame(() => {
    if (actions.abrirDireita.paused === true) {
      actions.moldeAbrir.play();
      actions.moldeAbrir.clampWhenFinished = true;
      actions.moldeAbrir.setLoop(LoopOnce);
      actions.moldeAbrir.timeScale = 3;

      actions.stickAbrir.play();
      actions.stickAbrir.clampWhenFinished = true;
      actions.stickAbrir.setLoop(LoopOnce);
      actions.stickAbrir.timeScale = 3;
    }
  });

  const playSapatoNormal = () => {
    setVisibleMolde(false);
    actions.sapatoUp.stop();
    actions["sapatoUp.001"].stop();
    actions["sapatoUp.002"].stop();
    actions["sapatoUp.003"].stop();
    actions["sapatoUp.004"].stop();
    actions["sapatoUp.005"].stop();
    actions.abrirDireita.stop();
    actions.abrirEsquerda.stop();
    actions.fecharDireita.stop();
    actions.fecharEsquerda.stop();
    actions.descerSapato.stop();
    actions["descerSapato.001"].stop();
    actions["descerSapato.002"].stop();
    actions["descerSapato.003"].stop();
    actions["idle.001"].stop();
    actions["idle"].stop();
    actions.explodir1.stop();
    actions.explodir2.stop();
    actions.solaInvis.stop();
    actions.stickAbrir.stop();
    actions.bottomInvis.stop();
    actions.moldeAbrir.stop();
    actions.sapatoInvis.stop();
    actions.moldeSubir.stop();
    actions.idleCima.stop();
  };

  const playSapatoExplode = () => {
    setVisibleMolde(false);
    actions.sapatoUp.stop();
    actions["sapatoUp.001"].stop();
    actions["sapatoUp.002"].stop();
    actions["sapatoUp.003"].stop();
    actions["sapatoUp.004"].stop();
    actions["sapatoUp.005"].stop();
    actions.abrirDireita.stop();
    actions.abrirEsquerda.stop();
    actions.fecharDireita.stop();
    actions.fecharEsquerda.stop();
    actions.descerSapato.stop();
    actions["descerSapato.001"].stop();
    actions["descerSapato.002"].stop();
    actions["descerSapato.003"].stop();
    actions["idle.001"].stop();
    actions["idle"].stop();
    actions.solaInvis.stop();
    actions.stickAbrir.stop();
    actions.bottomInvis.stop();
    actions.moldeAbrir.stop();
    actions.sapatoInvis.stop();
    actions.moldeSubir.stop();
    actions.idleCima.stop();
    actions.explodir1.play();
    actions.explodir1.clampWhenFinished = true;
    actions.explodir1.setLoop(LoopOnce);
    actions.explodir2.play();
    actions.explodir2.clampWhenFinished = true;
    actions.explodir2.setLoop(LoopOnce);
  };

  const playAnimFecharMolde = () => {
    setVisibleMolde(true);
    actions.sapatoUp.stop();
    actions["sapatoUp.001"].stop();
    actions["sapatoUp.002"].stop();
    actions["sapatoUp.003"].stop();
    actions["sapatoUp.004"].stop();
    actions["sapatoUp.005"].stop();
    actions.abrirDireita.stop();
    actions.abrirEsquerda.stop();
    actions.explodir1.stop();
    actions.explodir2.stop();
    actions.solaInvis.stop();
    actions.stickAbrir.stop();
    actions.bottomInvis.stop();
    actions.moldeAbrir.stop();
    actions.sapatoInvis.stop();
    actions.fecharDireita.play();
    actions.fecharDireita.clampWhenFinished = true;
    actions.fecharDireita.setLoop(LoopOnce);
    actions.fecharEsquerda.play();
    actions.fecharEsquerda.clampWhenFinished = true;
    actions.fecharEsquerda.setLoop(LoopOnce);
    actions.descerSapato.play();
    actions.descerSapato.clampWhenFinished = true;
    actions.descerSapato.setLoop(LoopOnce);
    actions["descerSapato.001"].play();
    actions["descerSapato.001"].clampWhenFinished = true;
    actions["descerSapato.001"].setLoop(LoopOnce);
    actions["descerSapato.002"].play();
    actions["descerSapato.002"].clampWhenFinished = true;
    actions["descerSapato.002"].setLoop(LoopOnce);
    actions["descerSapato.003"].play();
    actions["descerSapato.003"].clampWhenFinished = true;
    actions["descerSapato.003"].setLoop(LoopOnce);
    actions["idle.001"].play();
    actions["idle.001"].clampWhenFinished = true;
    actions["idle.001"].setLoop(LoopOnce);
    actions.moldeSubir.play();
    actions.moldeSubir.clampWhenFinished = true;
    actions.moldeSubir.setLoop(LoopOnce);
    actions.idleCima.play();
    actions.idleCima.clampWhenFinished = true;
    actions.idleCima.setLoop(LoopOnce);
  };

  const playAnimAbrirMolde = () => {
    setVisibleMolde(true);
    actions.fecharDireita.stop();
    actions.fecharEsquerda.stop();
    actions.descerSapato.stop();
    actions["descerSapato.001"].stop();
    actions["descerSapato.002"].stop();
    actions["descerSapato.003"].stop();
    actions.explodir1.stop();
    actions.explodir2.stop();
    actions["idle.001"].stop();
    actions["idle"].stop();
    actions.moldeSubir.stop();
    actions.idleCima.stop();
    actions.sapatoUp.play();
    actions.sapatoUp.clampWhenFinished = true;
    actions.sapatoUp.setLoop(LoopOnce);
    actions["sapatoUp.001"].play();
    actions["sapatoUp.001"].clampWhenFinished = true;
    actions["sapatoUp.001"].setLoop(LoopOnce);
    actions["sapatoUp.002"].play();
    actions["sapatoUp.002"].clampWhenFinished = true;
    actions["sapatoUp.002"].setLoop(LoopOnce);
    actions["sapatoUp.003"].play();
    actions["sapatoUp.003"].clampWhenFinished = true;
    actions["sapatoUp.003"].setLoop(LoopOnce);
    actions["sapatoUp.004"].play();
    actions["sapatoUp.004"].clampWhenFinished = true;
    actions["sapatoUp.004"].setLoop(LoopOnce);
    actions["sapatoUp.005"].play();
    actions["sapatoUp.005"].clampWhenFinished = true;
    actions["sapatoUp.005"].setLoop(LoopOnce);
    actions.abrirDireita.play();
    actions.abrirDireita.clampWhenFinished = true;
    actions.abrirDireita.setLoop(LoopOnce);
    actions.abrirEsquerda.play();
    actions.abrirEsquerda.clampWhenFinished = true;
    actions.abrirEsquerda.setLoop(LoopOnce);
    actions.solaInvis.play();
    actions.solaInvis.clampWhenFinished = true;
    actions.solaInvis.setLoop(LoopOnce);
    actions.bottomInvis.play();
    actions.bottomInvis.clampWhenFinished = true;
    actions.bottomInvis.setLoop(LoopOnce);
    actions.sapatoInvis.play();
    actions.sapatoInvis.clampWhenFinished = true;
    actions.sapatoInvis.setLoop(LoopOnce);
  };

  return (
    <Center>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <mesh
            name="moldeMeio"
            castShadow
            receiveShadow
            visible={visibleMolde ? true : false}
            geometry={nodes.moldeMeio.geometry}
            material={materials["blue backsef.004"]}
            position={[-1.19996262, -0.24064116, -0.47961047]}
            scale={1.02764416}
          />
          <mesh
            name="moldeEsquerda"
            castShadow
            receiveShadow
            visible={visibleMolde ? true : false}
            geometry={nodes.moldeEsquerda.geometry}
            material={materials["blue backsef.004"]}
            position={[-1.19920528, -0.22832426, -0.48390907]}
          />
          <mesh
            name="moldeDireita"
            castShadow
            receiveShadow
            visible={visibleMolde ? true : false}
            geometry={nodes.moldeDireita.geometry}
            material={materials["blue backsef.004"]}
            position={[-1.19903553, -0.22832426, -0.48390907]}
          />
          <group
            name="moldeStick"
            visible={visibleMolde ? true : false}
            position={[-1.19842935, 0.04322009, -0.47455412]}
          >
            <mesh
              name="moldeStick_1"
              castShadow
              receiveShadow
              geometry={nodes.moldeStick_1.geometry}
              material={materials["#787878"]}
            />
            <mesh
              name="moldeStick_2"
              castShadow
              receiveShadow
              geometry={nodes.moldeStick_2.geometry}
              material={materials["blue backsefdfg"]}
            />
            <mesh
              name="moldeStick_3"
              castShadow
              receiveShadow
              geometry={nodes.moldeStick_3.geometry}
              material={materials["blue backsef.004"]}
            />
          </group>
          <group
            name="molde"
            position={[-1.19842935, 0.04322009, -0.47455412]}
            visible={visibleMolde ? true : false}
          >
            <mesh
              name="molde_1"
              castShadow
              receiveShadow
              geometry={nodes.molde_1.geometry}
              material={materials["#808080"]}
            />
            <mesh
              name="molde_2"
              castShadow
              receiveShadow
              geometry={nodes.molde_2.geometry}
              material={materials["Material #503"]}
            />
            <mesh
              name="molde_3"
              castShadow
              receiveShadow
              geometry={nodes.molde_3.geometry}
              material={materials["Material #521"]}
            />
            <mesh
              name="molde_4"
              castShadow
              receiveShadow
              geometry={nodes.molde_4.geometry}
              material={materials["Material #523"]}
            />
            <mesh
              name="molde_5"
              castShadow
              receiveShadow
              geometry={nodes.molde_5.geometry}
              material={materials["Material #504"]}
            />
            <mesh
              name="molde_6"
              castShadow
              receiveShadow
              geometry={nodes.molde_6.geometry}
              material={materials["blue backsef.004"]}
            />
            <mesh
              name="molde_7"
              castShadow
              receiveShadow
              geometry={nodes.molde_7.geometry}
              material={materials["blue backsefdfg"]}
            />
            <mesh
              name="molde_8"
              castShadow
              receiveShadow
              geometry={nodes.molde_8.geometry}
              material={materials["blue backsefdfgef"]}
            />
            <mesh
              name="molde_9"
              castShadow
              receiveShadow
              geometry={nodes.molde_9.geometry}
              material={materials["#808080.001"]}
            />
            <mesh
              name="molde_10"
              castShadow
              receiveShadow
              geometry={nodes.molde_10.geometry}
              material={materials["Material #503.001"]}
            />
            <mesh
              name="molde_11"
              castShadow
              receiveShadow
              geometry={nodes.molde_11.geometry}
              material={materials.Metal}
            />
          </group>
          <group
            name="calcanhar"
            position={[-1.19842935, 0.04322009, -0.47455412]}
            visible={visibleMolde ? true : false}
          >
            <mesh
              name="calcanhar_1"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_1.geometry}
              material={materials["Material #506"]}
            />
            <mesh
              name="calcanhar_2"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_2.geometry}
              material={materials["Material #59"]}
            />
            <mesh
              name="calcanhar_3"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_3.geometry}
              material={materials["#D2D2D2"]}
            />
            <mesh
              name="calcanhar_4"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_4.geometry}
              material={materials["blue backsefdfg"]}
            />
          </group>
          <group
            name="Bottom"
            position={[-1.19840002, 0.04329451, -0.47455001]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <mesh
              name="PU_out002002"
              castShadow
              receiveShadow
              geometry={nodes.PU_out002002.geometry}
              material={materials["Sola.002"]}
            />
            <mesh
              name="PU_out002002_1"
              castShadow
              receiveShadow
              geometry={nodes.PU_out002002_1.geometry}
              material={materials["blue backsef.002"]}
            />
          </group>
          <mesh
            name="sola"
            castShadow
            receiveShadow
            geometry={nodes.sola.geometry}
            material={materials["Sola.002"]}
            position={[-1.19840002, 0.04329451, -0.47455001]}
            rotation={[Math.PI / 2, 0, 0]}
          />
          <group
            name="sapado"
            position={[-1.19842935, 0.04322009, -0.47455412]}
            rotation={[Math.PI / 2, 0, -3.1341331]}
          >
            <mesh
              name="sapato"
              castShadow
              receiveShadow
              geometry={nodes.sapato.geometry}
              material={materials["frente.003"]}
            />
            <mesh
              name="sapato_1"
              castShadow
              receiveShadow
              geometry={nodes.sapato_1.geometry}
              material={materials["preenchimentoed.003"]}
            />
            <mesh
              name="sapato_2"
              castShadow
              receiveShadow
              geometry={nodes.sapato_2.geometry}
              material={materials["blue back.003"]}
            />
            <mesh
              name="sapato_3"
              castShadow
              receiveShadow
              geometry={nodes.sapato_3.geometry}
              material={materials["preenchimento.003"]}
            />
            <mesh
              name="sapato_4"
              castShadow
              receiveShadow
              geometry={nodes.sapato_4.geometry}
              material={materials["Lateral.003"]}
            />
            <mesh
              name="sapato_5"
              castShadow
              receiveShadow
              geometry={nodes.sapato_5.geometry}
              material={materials["lado.003"]}
            />
            <mesh
              name="sapato_6"
              castShadow
              receiveShadow
              geometry={nodes.sapato_6.geometry}
              material={materials["Sola.003"]}
            />
            <mesh
              name="sapato_7"
              castShadow
              receiveShadow
              geometry={nodes.sapato_7.geometry}
              material={materials["ecco.003"]}
            />
            <mesh
              name="sapato_8"
              castShadow
              receiveShadow
              geometry={nodes.sapato_8.geometry}
              material={materials["Material.001"]}
            />
          </group>
        </group>
      </group>
    </Center>
  );
}

useGLTF.preload("/modelo1.glb");

function Modelo1() {
  const [fecharMolde, setFecharMolde] = useState(false);
  const [abrirMolde, setAbrirMolde] = useState(false);
  const [sapatoNormal, setSapatoNormal] = useState(true);
  const [sapatoExplode, setSapatoExplode] = useState(false);

  const playFecharMolde = () => {
    setFecharMolde(true);
    setAbrirMolde(false);
    setSapatoNormal(false);
    setSapatoExplode(false);
  };

  const playAbrirMolde = () => {
    setAbrirMolde(true);
    setFecharMolde(false);
    setSapatoNormal(false);
    setSapatoExplode(false);
  };

  const playSapatoNormal = () => {
    setSapatoNormal(true);
    setFecharMolde(false);
    setAbrirMolde(false);
    setSapatoExplode(false);
  };

  const playSapatoExplode = () => {
    setSapatoExplode(true);
    setSapatoNormal(false);
    setFecharMolde(false);
    setAbrirMolde(false);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f8f8",
        overflow: "hidden",
      }}
    >
      <div className="controlsMain">
        <div className="controls">
          <Button
            variant="contained"
            onClick={playSapatoNormal}
            disabled={sapatoNormal ? true : false}
            className="btnAnimation"
          >
            Shoe
          </Button>
          <Button
            variant="contained"
            onClick={playSapatoExplode}
            disabled={sapatoExplode ? true : false}
            className="btnAnimation"
          >
            Explode Shoe
          </Button>
          <Button
            variant="contained"
            onClick={playFecharMolde}
            disabled={fecharMolde ? true : false}
            className="btnAnimation"
          >
            Close Mold
          </Button>
          <Button
            variant="contained"
            onClick={playAbrirMolde}
            disabled={abrirMolde ? true : false}
            className="btnAnimation"
          >
            Open Mold
          </Button>
        </div>
      </div>
      <div className="modelDiv">
        <Canvas>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} />

            <ModelExplode
              fecharMolde={fecharMolde}
              abrirMolde={abrirMolde}
              sapatoNormal={sapatoNormal}
              sapatoExplode={sapatoExplode}
              scale={6}
              rotation={[0, -20, 0]}
            />

            <Environment files={"hdr2.hdr"} />
            <ViewCube />
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <Button
          variant="contained"
          onClick={playSapatoNormal}
          disabled={sapatoNormal ? true : false}
          className="btnAnimation"
        >
          Shoe
        </Button>
        <Button
          variant="contained"
          onClick={playSapatoExplode}
          disabled={sapatoExplode ? true : false}
          className="btnAnimation"
        >
          Explode Shoe
        </Button>
        <Button
          variant="contained"
          onClick={playFecharMolde}
          disabled={fecharMolde ? true : false}
          className="btnAnimation"
        >
          Close Mold
        </Button>
        <Button
          variant="contained"
          onClick={playAbrirMolde}
          disabled={abrirMolde ? true : false}
          className="btnAnimation"
        >
          Open Mold
        </Button>
      </div>
    </div>
  );
}

export default Modelo1;
