import React, { useRef, useEffect, Suspense, useState, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import { LoopOnce } from "three";
import { Loop } from "@mui/icons-material";
import { Button } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/Modelo0.glb");
  const { actions } = useAnimations(animations, group);

  console.log(actions);
  const [visibleMolde, setVisibleMolde] = useState(false);

  useEffect(() => {
    if (props.fecharMolde) {
      playAnimFecharMolde();
    }
  }, [props.fecharMolde]);

  useEffect(() => {
    if (props.abrirMolde) {
      playAnimAbrirMolde();
    }
  }, [props.abrirMolde]);

  useEffect(() => {
    if (props.sapatoNormal) {
      playAnimSapatoNormal();
    }
  }, [props.sapatoNormal]);

  const playAnimSapatoNormal = () => {
    setVisibleMolde(false);
    actions.BoneEsquerda2.stop();
    actions.blackStitchEsquerda.stop();
    actions.blackStitchFechar.stop();
    actions.boneAnimationDireita.stop();
    actions.boneAnimationEsquerda.stop();
    actions.boneDireita2.stop();
    actions.idle.stop();
    actions.idleBlackStitch.stop();
    actions.idleBoneDireita.stop();
    actions.idleBoneEsquerda.stop();
    actions.modeCimaDescer.stop();
    actions.moldeDireitaFechar.stop();
    actions.moldecimaAbre.stop();
    actions.moldedireitoFechar.stop();
    actions.moldeesquerdoAbre.stop();
    actions.moldeesquerdoFecha.stop();
    actions.stitchGone.stop();
    actions.stitchappear.stop();
  };

  const playAnimFecharMolde = () => {
    setVisibleMolde(true);
    actions.BoneEsquerda2.stop();
    actions.blackStitchFechar.stop();
    actions.boneDireita2.stop();
    actions.idle.stop();
    actions.idleBlackStitch.stop();
    actions.idleBoneDireita.stop();
    actions.idleBoneEsquerda.stop();
    actions.moldecimaAbre.stop();
    actions.moldedireitoFechar.stop();
    actions.moldeesquerdoFecha.stop();
    actions.stitchappear.stop();
    actions.boneAnimationDireita.play();
    actions.boneAnimationDireita.clampWhenFinished = true;
    actions.boneAnimationDireita.setLoop(LoopOnce);
    actions.boneAnimationEsquerda.play();
    actions.boneAnimationEsquerda.clampWhenFinished = true;
    actions.boneAnimationEsquerda.setLoop(LoopOnce);
    actions.moldeDireitaFechar.play();
    actions.moldeDireitaFechar.clampWhenFinished = true;
    actions.moldeDireitaFechar.setLoop(LoopOnce);
    actions.moldeesquerdoAbre.play();
    actions.moldeesquerdoAbre.clampWhenFinished = true;
    actions.moldeesquerdoAbre.setLoop(LoopOnce);
    actions.modeCimaDescer.play();
    actions.modeCimaDescer.clampWhenFinished = true;
    actions.modeCimaDescer.setLoop(LoopOnce);
    actions.blackStitchEsquerda.play();
    actions.blackStitchEsquerda.clampWhenFinished = true;
    actions.blackStitchEsquerda.setLoop(LoopOnce);
    actions.stitchGone.play();
    actions.stitchGone.clampWhenFinished = true;
    actions.stitchGone.setLoop(LoopOnce);
  };

  const playAnimAbrirMolde = () => {
    setVisibleMolde(true);
    actions.idle.stop();
    actions.idleBlackStitch.stop();
    actions.idleBoneDireita.stop();
    actions.idleBoneEsquerda.stop();
    actions.blackStitchEsquerda.stop();
    actions.boneAnimationDireita.stop();
    actions.boneAnimationEsquerda.stop();
    actions.moldeDireitaFechar.stop();
    actions.moldeesquerdoAbre.stop();
    actions.modeCimaDescer.stop();
    actions.stitchGone.stop();
    actions.boneDireita2.play();
    actions.boneDireita2.clampWhenFinished = true;
    actions.boneDireita2.setLoop(LoopOnce);
    actions.BoneEsquerda2.play();
    actions.BoneEsquerda2.clampWhenFinished = true;
    actions.BoneEsquerda2.setLoop(LoopOnce);
    actions.blackStitchFechar.play();
    actions.blackStitchFechar.clampWhenFinished = true;
    actions.blackStitchFechar.setLoop(LoopOnce);
    actions.moldeesquerdoFecha.play();
    actions.moldeesquerdoFecha.clampWhenFinished = true;
    actions.moldeesquerdoFecha.setLoop(LoopOnce);
    actions.moldeesquerdoFecha.timeScale = 1.5;
    actions.moldedireitoFechar.play();
    actions.moldedireitoFechar.clampWhenFinished = true;
    actions.moldedireitoFechar.setLoop(LoopOnce);
    actions.moldedireitoFechar.timeScale = 1.5;
    actions.moldecimaAbre.play();
    actions.moldecimaAbre.clampWhenFinished = true;
    actions.moldecimaAbre.setLoop(LoopOnce);
    actions.moldecimaAbre.timeScale = 2;
    actions.stitchappear.play();
    actions.stitchappear.clampWhenFinished = true;
    actions.stitchappear.setLoop(LoopOnce);
  };

  return (
    <Center>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="moldeEsquerdapai"
            visible={visibleMolde ? true : false}
            position={[0.47097808, 0.02012039, -0.01951164]}
            scale={0.02188955}
          >
            <mesh
              name="moldeEsquerda"
              castShadow
              receiveShadow
              geometry={nodes.moldeEsquerda.geometry}
              material={materials.metal}
              position={[-19.51625443, -3.91814828, 0.99659979]}
            />
          </group>
          <group
            name="Sola"
            position={[0.00799329, 0.03028183, 0.01674761]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.01945654, 0.02033873, 0.01945654]}
          >
            <group name="PILLOW_HOOK" />
            <group name="SOLE">
              <mesh
                name="polySurface2"
                castShadow
                receiveShadow
                geometry={nodes.polySurface2.geometry}
                material={materials.Material}
              />
              <mesh
                name="polySurface4"
                castShadow
                receiveShadow
                geometry={nodes.polySurface4.geometry}
                material={materials.Material}
              />
              <mesh
                name="polySurface5"
                castShadow
                receiveShadow
                geometry={nodes.polySurface5.geometry}
                material={materials.Material}
              />
              <mesh
                name="polySurface1"
                castShadow
                receiveShadow
                geometry={nodes.polySurface1.geometry}
                material={materials.R622090_PU_009}
              />
              <mesh
                name="polySurface27"
                castShadow
                receiveShadow
                geometry={nodes.polySurface27.geometry}
                material={materials.R622090_PU_009}
              />
            </group>
            <group name="StitchesPai">
              <mesh
                name="Stitches"
                castShadow
                receiveShadow
                geometry={nodes.Stitches.geometry}
                material={materials["Material.002"]}
                position={[3.03143764, -6.27530289, -4.96249676]}
              />
            </group>
            <mesh
              name="Inlaysole"
              castShadow
              receiveShadow
              geometry={nodes.Inlaysole.geometry}
              material={materials["Material.001"]}
            />
          </group>
          <group
            name="boneDireito"
            position={[0.10621666, 0.05613983, -0.02991638]}
            rotation={[0, 0, 0.25879053]}
            scale={0.04949048}
          >
            <primitive object={nodes.Bone} />
            <group name="lateralDireita">
              <skinnedMesh
                name="Mesh012"
                geometry={nodes.Mesh012.geometry}
                material={materials.lambert1}
                skeleton={nodes.Mesh012.skeleton}
              />
              <skinnedMesh
                name="Mesh012_1"
                geometry={nodes.Mesh012_1.geometry}
                material={materials.defaultPolygonShader1}
                skeleton={nodes.Mesh012_1.skeleton}
              />
            </group>
          </group>
          <group name="boneEsquero" position={[-0.18796586, 0, 0]}>
            <primitive object={nodes.Bone_1} />
            <primitive object={nodes.ABone1} />
            <primitive object={nodes.Bone011} />
            <skinnedMesh
              name="lateralEsquerda"
              geometry={nodes.lateralEsquerda.geometry}
              material={materials.lambert1}
              skeleton={nodes.lateralEsquerda.skeleton}
            />
          </group>
          <mesh
            name="moldeBaixo"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.moldeBaixo.geometry}
            material={materials.metal}
            position={[0.03824919, 0.23418173, 0.00237791]}
            scale={0.02188955}
          />
          <mesh
            name="moldeTras"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.moldeTras.geometry}
            material={materials.metal}
            position={[0.03945956, 0.1534674, 0.00237791]}
            scale={0.02188955}
          />
          <mesh
            name="moldeDireita"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.moldeDireita.geometry}
            material={materials.metal}
            position={[-0.11238924, -0.06557721, 0.00237791]}
            scale={0.02188955}
          />
          <mesh
            name="MoldeCima"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.MoldeCima.geometry}
            material={materials.metal}
            position={[0.03202439, 0.26670003, 0.00237791]}
            scale={0.02188955}
          />
          <mesh
            name="blackStitch"
            castShadow
            receiveShadow
            geometry={nodes.blackStitch.geometry}
            material={materials.defaultPolygonShader1}
            position={[0.06680064, 0.11990534, -0.11169648]}
            rotation={[Math.PI / 2, 0, 0]}
          />
        </group>
      </group>
    </Center>
  );
}

useGLTF.preload("/Modelo0.glb");

function Modelo0() {
  const [fecharMolde, setFecharMolde] = useState(false);
  const [abrirMolde, setAbrirMolde] = useState(false);
  const [sapatoNormal, setSapatoNormal] = useState(true);

  const playFecharMolde = () => {
    setSapatoNormal(false);
    setFecharMolde(true);
    setAbrirMolde(false);
  };

  const playAbrirMolde = () => {
    setAbrirMolde(true);
    setFecharMolde(false);
    setSapatoNormal(false);
  };

  const playSapatoNormal = () => {
    setSapatoNormal(true);
    setFecharMolde(false);
    setAbrirMolde(false);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f8f8",
        overflow: "hidden",
      }}
    >
      <div className="controlsMain">
        <div className="controls">
          <Button
            variant="contained"
            onClick={playSapatoNormal}
            disabled={sapatoNormal ? true : false}
            className="btnAnimation"
          >
            Shoe
          </Button>
          <Button
            variant="contained"
            onClick={playFecharMolde}
            disabled={fecharMolde ? true : false}
            className="btnAnimation"
          >
            Close Mold
          </Button>
          <Button
            variant="contained"
            onClick={playAbrirMolde}
            disabled={abrirMolde ? true : false}
            className="btnAnimation"
          >
            Open Mold
          </Button>
        </div>
      </div>
      <div className="modelDiv">
        <Canvas camera={{ position: [10, 0, 10] }}>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} />

            <ModelExplode
              fecharMolde={fecharMolde}
              abrirMolde={abrirMolde}
              sapatoNormal={sapatoNormal}
              scale={10}
            />

            <Environment files={"hdr2.hdr"} />
            <ViewCube />
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <Button
          variant="contained"
          onClick={playSapatoNormal}
          disabled={sapatoNormal ? true : false}
          className="btnAnimation"
        >
          Shoe
        </Button>
        <Button
          variant="contained"
          onClick={playFecharMolde}
          disabled={fecharMolde ? true : false}
          className="btnAnimation"
        >
          Close Mold
        </Button>
        <Button
          variant="contained"
          onClick={playAbrirMolde}
          disabled={abrirMolde ? true : false}
          className="btnAnimation"
        >
          Open Mold
        </Button>
      </div>
    </div>
  );
}

export default Modelo0;
