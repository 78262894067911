import React, { useRef, useEffect, Suspense, useState, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
  useCursor,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import { LoopOnce } from "three";
import { Loop } from "@mui/icons-material";
import { Button } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/modelo3.glb");
  const { actions } = useAnimations(animations, group);

  const [visibleMolde, setVisibleMolde] = useState(false);
  const [visibleSola, setVisibleSola] = useState(true);
  const [visibleMoldeSola, setVisibleMoldeSola] = useState(false);
  const [visibleSolaPreta, setVisibleSolaPreta] = useState(true);

  console.log(actions);

  useEffect(() => {
    if (props.sapatoNormal) {
      playSapatoNormal();
    }
  }, [props.sapatoNormal]);

  useEffect(() => {
    if (props.sapatoExplode) {
      playSapatoExplode();
    }
  }, [props.sapatoExplode]);

  useEffect(() => {
    if (props.fecharMolde) {
      playAnimFecharMolde();
    }
  }, [props.fecharMolde]);

  useEffect(() => {
    if (props.abrirMolde) {
      playAnimAbrirMolde();
    }
  }, [props.abrirMolde]);

  const playSapatoNormal = () => {
    setVisibleMolde(false);
    setVisibleSola(true);
    setVisibleMoldeSola(false);
    setVisibleSolaPreta(true);
    actions.bottomAbrir.stop();
    actions.abrirSola.stop();
    actions.bottomDescer.stop();
    actions.fecharDireita.stop();
    actions.fecharEsquerda.stop();
    actions.sapatoDescer.stop();
    actions.moldeDescer.stop();
    actions.calcanharDescer.stop();
    actions.stickDescer.stop();
    actions.bottomSubir.stop();
    actions.stickSubir.stop();
    actions.sapatoSubir.stop();
    actions.moldeSubir.stop();
    actions.abrirDireita.stop();
    actions.abrirEsquerda.stop();
    actions.solaSubir.stop();
    actions["All Animations"].stop();
    actions.moldeSola.stop();
    actions.solaPretaAbrir.stop();
    actions.solapretaSubir.stop();
  };

  const playSapatoExplode = () => {
    setVisibleMolde(false);
    setVisibleSola(true);
    setVisibleMoldeSola(false);
    setVisibleSolaPreta(true);
    actions.bottomAbrir.play();
    actions.bottomAbrir.clampWhenFinished = true;
    actions.bottomAbrir.setLoop(LoopOnce);
    actions.abrirSola.play();
    actions.abrirSola.clampWhenFinished = true;
    actions.abrirSola.setLoop(LoopOnce);
    actions.solaPretaAbrir.play();
    actions.solaPretaAbrir.clampWhenFinished = true;
    actions.solaPretaAbrir.setLoop(LoopOnce);
    actions.bottomDescer.stop();
    actions.fecharDireita.stop();
    actions.fecharEsquerda.stop();
    actions.sapatoDescer.stop();
    actions.moldeDescer.stop();
    actions.calcanharDescer.stop();
    actions.stickDescer.stop();
    actions.bottomSubir.stop();
    actions.stickSubir.stop();
    actions.sapatoSubir.stop();
    actions.moldeSubir.stop();
    actions.abrirDireita.stop();
    actions.abrirEsquerda.stop();
    actions.solaSubir.stop();
    actions["All Animations"].stop();
    actions.moldeSola.stop();
    actions.solapretaSubir.stop();
  };

  const playAnimFecharMolde = () => {
    setVisibleMolde(true);
    setVisibleSola(false);
    setVisibleMoldeSola(true);
    setVisibleSolaPreta(false);

    actions.bottomAbrir.stop();
    actions.abrirSola.stop();
    actions.bottomDescer.stop();
    actions.fecharDireita.stop();
    actions.fecharEsquerda.stop();
    actions.sapatoDescer.stop();
    actions.moldeDescer.stop();
    actions.calcanharDescer.stop();
    actions.stickDescer.stop();
    actions.bottomSubir.stop();
    actions.stickSubir.stop();
    actions.sapatoSubir.stop();
    actions.moldeSubir.stop();
    actions.abrirDireita.stop();
    actions.abrirEsquerda.stop();
    actions.solaSubir.stop();
    actions["All Animations"].stop();
    actions.solaPretaAbrir.stop();
    actions.solapretaSubir.stop();

    actions.bottomDescer.play();
    actions.bottomDescer.clampWhenFinished = true;
    actions.bottomDescer.setLoop(LoopOnce);
    actions.fecharDireita.play();
    actions.fecharDireita.clampWhenFinished = true;
    actions.fecharDireita.setLoop(LoopOnce);
    actions.fecharEsquerda.play();
    actions.fecharEsquerda.clampWhenFinished = true;
    actions.fecharEsquerda.setLoop(LoopOnce);
    actions.sapatoDescer.play();
    actions.sapatoDescer.clampWhenFinished = true;
    actions.sapatoDescer.setLoop(LoopOnce);
    actions.moldeDescer.play();
    actions.moldeDescer.clampWhenFinished = true;
    actions.moldeDescer.setLoop(LoopOnce);
    actions.moldeSola.play();
    actions.moldeSola.clampWhenFinished = true;
    actions.moldeSola.setLoop(LoopOnce);
    actions.calcanharDescer.play();
    actions.calcanharDescer.clampWhenFinished = true;
    actions.calcanharDescer.setLoop(LoopOnce);
    actions.stickDescer.play();
    actions.stickDescer.clampWhenFinished = true;
    actions.stickDescer.setLoop(LoopOnce);
  };

  const playAnimAbrirMolde = () => {
    setVisibleMolde(true);
    setVisibleSola(true);
    setVisibleMoldeSola(true);
    setVisibleSolaPreta(false);
    actions.bottomAbrir.stop();
    actions.abrirSola.stop();
    actions.bottomDescer.stop();
    actions.fecharDireita.stop();
    actions.fecharEsquerda.stop();
    actions.sapatoDescer.stop();
    actions.moldeDescer.stop();
    actions.calcanharDescer.stop();
    actions.moldeSola.stop();
    actions.stickDescer.stop();
    actions.solaPretaAbrir.stop();
    actions.bottomSubir.play();
    actions.bottomSubir.clampWhenFinished = true;
    actions.bottomSubir.setLoop(LoopOnce);
    actions.stickSubir.play();
    actions.stickSubir.clampWhenFinished = true;
    actions.stickSubir.setLoop(LoopOnce);
    actions.sapatoSubir.play();
    actions.sapatoSubir.clampWhenFinished = true;
    actions.sapatoSubir.setLoop(LoopOnce);
    actions.moldeSubir.play();
    actions.moldeSubir.clampWhenFinished = true;
    actions.moldeSubir.setLoop(LoopOnce);
    actions["All Animations"].play();
    actions["All Animations"].clampWhenFinished = true;
    actions["All Animations"].setLoop(LoopOnce);
    actions.abrirDireita.play();
    actions.abrirDireita.clampWhenFinished = true;
    actions.abrirDireita.setLoop(LoopOnce);
    actions.abrirEsquerda.play();
    actions.abrirEsquerda.clampWhenFinished = true;
    actions.abrirEsquerda.setLoop(LoopOnce);
    actions.solaSubir.play();
    actions.solaSubir.clampWhenFinished = true;
    actions.solaSubir.setLoop(LoopOnce);
    actions.solapretaSubir.play();
    actions.solapretaSubir.clampWhenFinished = true;
    actions.solapretaSubir.setLoop(LoopOnce);
  };

  return (
    <Center>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="stick"
            visible={visibleMolde ? true : false}
            position={[-0.042497, 0.97709602, -0.15737]}
            scale={6.34209156}
          >
            <mesh
              name="stitch_result_stitch_all009"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all009.geometry}
              material={materials["#787878"]}
            />
            <mesh
              name="stitch_result_stitch_all009_1"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all009_1.geometry}
              material={materials["#808080"]}
            />
          </group>
          <mesh
            name="moldeDireita"
            castShadow
            receiveShadow
            visible={visibleMolde ? true : false}
            geometry={nodes.moldeDireita.geometry}
            material={materials["blue backsef"]}
            position={[0.64633477, -0.60924268, -0.04177964]}
            scale={5.98034}
          />
          <mesh
            name="moldeMeio"
            castShadow
            receiveShadow
            visible={visibleMolde ? true : false}
            geometry={nodes.moldeMeio.geometry}
            material={materials["blue backsef"]}
            position={[-0.04424346, -0.5278827, -0.12484401]}
            scale={5.98034}
          />
          <mesh
            name="moldeEsquerda"
            castShadow
            receiveShadow
            visible={visibleMolde ? true : false}
            geometry={nodes.moldeEsquerda.geometry}
            material={materials["blue backsef"]}
            position={[-1.11154151, -0.33391494, -0.13285044]}
            scale={5.99045181}
          />
          <mesh
            name="sola"
            castShadow
            receiveShadow
            visible={visibleSola ? true : false}
            geometry={nodes.sola.geometry}
            material={materials["SolaBota.001"]}
            position={[-0.042497, 0.98777747, -0.15737]}
            scale={[6.69975662, 6.34209108, 6.34209108]}
          />
          <mesh
            name="bottom"
            castShadow
            receiveShadow
            geometry={nodes.bottom.geometry}
            material={materials["lambert7.003"]}
            position={[-0.042497, 0.96773112, -0.15737]}
            scale={6.34209156}
          />
          <mesh
            name="moldeSola"
            castShadow
            receiveShadow
            visible={visibleMoldeSola ? true : false}
            geometry={nodes.moldeSola.geometry}
            material={materials["SolaBotapreto.001"]}
            position={[-0.04985319, -0.39724588, 0.59461737]}
            scale={0.00591462}
          />
          <mesh
            name="solaPreta"
            castShadow
            receiveShadow
            visible={visibleSolaPreta ? true : false}
            geometry={nodes.solaPreta.geometry}
            material={materials["SolaBotapreto.001"]}
            position={[-0.042497, 0.98777747, -0.15737]}
            scale={[7.1136241, 6.34209108, 6.34209108]}
          />
          <group
            name="moldeSapato"
            visible={visibleMolde ? true : false}
            position={[-0.042497, 0.97709602, -0.15737]}
            scale={[6.34209013, 6.34209156, 6.34209156]}
          >
            <mesh
              name="stitch_result_stitch_all003595"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595.geometry}
              material={materials["#808080"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_1"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_1.geometry}
              material={materials["#787878"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_2"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_2.geometry}
              material={materials.rgb_colour_6}
            />
            <mesh
              name="stitch_result_stitch_all003595_3"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_3.geometry}
              material={materials["Material #918"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_4"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_4.geometry}
              material={materials["Material #919"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_5"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_5.geometry}
              material={materials["#969696"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_6"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_6.geometry}
              material={materials["Material #923"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_7"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_7.geometry}
              material={materials["Material #924"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_8"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_8.geometry}
              material={materials["Material #919.002"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_9"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_9.geometry}
              material={materials["#D2D2D2.002"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_10"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_10.geometry}
              material={materials["Material #918.003"]}
            />
            <mesh
              name="stitch_result_stitch_all003595_11"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all003595_11.geometry}
              material={materials["Material #918.004"]}
            />
          </group>
          <group
            name="calcanhar"
            visible={visibleMolde ? true : false}
            position={[-0.042497, 0.97709602, -0.15737]}
            scale={5.99045181}
          >
            <mesh
              name="stitch_result_stitch_all010011"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all010011.geometry}
              material={materials["#DB6D54"]}
            />
            <mesh
              name="stitch_result_stitch_all010011_1"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all010011_1.geometry}
              material={materials["#787878"]}
            />
            <mesh
              name="stitch_result_stitch_all010011_2"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all010011_2.geometry}
              material={materials["Material #918"]}
            />
            <mesh
              name="stitch_result_stitch_all010011_3"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all010011_3.geometry}
              material={materials["#969696"]}
            />
            <mesh
              name="stitch_result_stitch_all010011_4"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all010011_4.geometry}
              material={materials["#D2D2D2"]}
            />
            <mesh
              name="stitch_result_stitch_all010011_5"
              castShadow
              receiveShadow
              geometry={nodes.stitch_result_stitch_all010011_5.geometry}
              material={materials["Material #918.001"]}
            />
          </group>
          <group
            name="sapato"
            position={[-0.02764877, 1.50389647, -0.15737]}
            scale={[6.53527212, 6.34209013, 6.34209013]}
          >
            <mesh
              name="Vamp002"
              castShadow
              receiveShadow
              geometry={nodes.Vamp002.geometry}
              material={materials["frentebota.003"]}
            />
            <mesh
              name="Vamp002_1"
              castShadow
              receiveShadow
              geometry={nodes.Vamp002_1.geometry}
              material={materials["lambert7.002"]}
            />
            <mesh
              name="Vamp002_2"
              castShadow
              receiveShadow
              geometry={nodes.Vamp002_2.geometry}
              material={materials["Fecho.003"]}
            />
          </group>
        </group>
      </group>
    </Center>
  );
}

useGLTF.preload("/modelo3.glb");

function Modelo3() {
  const [fecharMolde, setFecharMolde] = useState(false);
  const [abrirMolde, setAbrirMolde] = useState(false);
  const [sapatoNormal, setSapatoNormal] = useState(true);
  const [sapatoExplode, setSapatoExplode] = useState(false);

  const playFecharMolde = () => {
    setFecharMolde(true);
    setAbrirMolde(false);
    setSapatoNormal(false);
    setSapatoExplode(false);
  };

  const playAbrirMolde = () => {
    setAbrirMolde(true);
    setFecharMolde(false);
    setSapatoNormal(false);
    setSapatoExplode(false);
  };

  const playSapatoNormal = () => {
    setSapatoNormal(true);
    setFecharMolde(false);
    setAbrirMolde(false);
    setSapatoExplode(false);
  };

  const playSapatoExplode = () => {
    setSapatoExplode(true);
    setSapatoNormal(false);
    setFecharMolde(false);
    setAbrirMolde(false);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f8f8",
        overflow: "hidden",
      }}
    >
      <div className="controlsMain">
        <div className="controls">
          <Button
            variant="contained"
            onClick={playSapatoNormal}
            disabled={sapatoNormal ? true : false}
            className="btnAnimation"
          >
            Shoe
          </Button>
          <Button
            variant="contained"
            onClick={playSapatoExplode}
            disabled={sapatoExplode ? true : false}
            className="btnAnimation"
          >
            Explode Shoe
          </Button>
          <Button
            variant="contained"
            onClick={playFecharMolde}
            disabled={fecharMolde ? true : false}
            className="btnAnimation"
          >
            Close Mold
          </Button>
          <Button
            variant="contained"
            onClick={playAbrirMolde}
            disabled={abrirMolde ? true : false}
            className="btnAnimation"
          >
            Open Mold
          </Button>
        </div>
      </div>
      <div className="modelDiv">
        <Canvas>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} />

            <ModelExplode
              fecharMolde={fecharMolde}
              abrirMolde={abrirMolde}
              sapatoNormal={sapatoNormal}
              sapatoExplode={sapatoExplode}
              scale={1.5}
            />

            <Environment files={"hdr2.hdr"} />
            <ViewCube />
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <Button
          variant="contained"
          onClick={playSapatoNormal}
          disabled={sapatoNormal ? true : false}
          className="btnAnimation"
        >
          Shoe
        </Button>
        <Button
          variant="contained"
          onClick={playSapatoExplode}
          disabled={sapatoExplode ? true : false}
          className="btnAnimation"
        >
          Explode Shoe
        </Button>
        <Button
          variant="contained"
          onClick={playFecharMolde}
          disabled={fecharMolde ? true : false}
          className="btnAnimation"
        >
          Close Mold
        </Button>
        <Button
          variant="contained"
          onClick={playAbrirMolde}
          disabled={abrirMolde ? true : false}
          className="btnAnimation"
        >
          Open Mold
        </Button>
      </div>
    </div>
  );
}

export default Modelo3;
