import React, { useRef, useEffect, Suspense, useState, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
  PointMaterial,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import { LoopOnce, PointsMaterial, Points } from "three";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader.js";
import { Loop } from "@mui/icons-material";
import { Button } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";

function ModelExplode(props) {
  const group = useRef();
  let { nodes, materials, animations, geometry } = useGLTF("/modelo02.glb");
  const { actions } = useAnimations(animations, group);

  // materials["leather02.002"] = new PointsMaterial({ color: "red", size: 0.25 });
  // materials["leather03.002"] = new PointsMaterial({ color: "blue", size: 0.25 });
  // // nodes["leather02.002"] = new Points(nodes["leather02.002"].geometry, materials["leather02.002"]);

  // console.log("model", useGLTF("/modelo02.glb"));
  // console.log("geometry", geometry);
  // console.log("materials", materials);

  const [visibleMolde, setVisibleMolde] = useState(false);
  const [visibleCalcanhar, setVisibleCalcanhar] = useState(false);

  useEffect(() => {
    if (props.sapatoNormal) {
      playSapatoNormal();
    }
  }, [props.sapatoNormal]);

  useEffect(() => {
    if (props.sapatoExplode) {
      playSapatoExplode();
    }
  }, [props.sapatoExplode]);

  useEffect(() => {
    if (props.fecharMolde) {
      playAnimFecharMolde();
    }
  }, [props.fecharMolde]);

  useEffect(() => {
    if (props.abrirMolde) {
      playAnimAbrirMolde();
    }
  }, [props.abrirMolde]);

  console.log(animations);

  const playSapatoNormal = () => {
    setVisibleMolde(false);
    setVisibleCalcanhar(false);
    actions.explodirBorracha.stop();
    actions.explodirSapato.stop();
    actions.explodirSola.stop();
    actions.fecharBorracha.stop();
    actions.fecharBottom.stop();
    actions.fecharMoldeBorracha.stop();
    actions.fecharMoldeDireita.stop();
    actions.fecharMoldeEsquerda.stop();
    actions.fecharSapato.stop();
    actions.fecharSola.stop();
    actions.abrirBorracha.stop();
    actions.abrirBottom.stop();
    actions.abrirMoldeBorracha.stop();
    actions.abrirMoldeDireita.stop();
    actions.abrirMoldeEsquerda.stop();
    actions.abrirSapato.stop();
    actions.abrirSola.stop();
    actions.descerMoldejunto.stop();
    actions.descerMoldejunto1.stop();
    actions.descerStick.stop();
    actions.subirStick.stop();
    actions.subirMolde.stop();
    actions.subirMolde1.stop();
  };

  const playSapatoExplode = () => {
    setVisibleMolde(false);
    setVisibleCalcanhar(false);
    actions.explodirBorracha.play();
    actions.explodirBorracha.clampWhenFinished = true;
    actions.explodirBorracha.setLoop(LoopOnce);
    actions.explodirSapato.play();
    actions.explodirSapato.clampWhenFinished = true;
    actions.explodirSapato.setLoop(LoopOnce);
    actions.explodirSola.play();
    actions.explodirSola.clampWhenFinished = true;
    actions.explodirSola.setLoop(LoopOnce);
    actions.fecharBorracha.stop();
    actions.fecharBottom.stop();
    actions.fecharMoldeBorracha.stop();
    actions.fecharMoldeDireita.stop();
    actions.fecharMoldeEsquerda.stop();
    actions.fecharSapato.stop();
    actions.fecharSola.stop();
    actions.abrirBorracha.stop();
    actions.abrirBottom.stop();
    actions.abrirMoldeBorracha.stop();
    actions.abrirMoldeDireita.stop();
    actions.abrirMoldeEsquerda.stop();
    actions.abrirSapato.stop();
    actions.abrirSola.stop();
    actions.descerMoldejunto.stop();
    actions.descerMoldejunto1.stop();
    actions.descerStick.stop();
    actions.subirStick.stop();
    actions.subirMolde.stop();
    actions.subirMolde1.stop();
  };

  const playAnimFecharMolde = () => {
    setVisibleMolde(true);
    setVisibleCalcanhar(false);
    actions.explodirBorracha.stop();
    actions.explodirSapato.stop();
    actions.explodirSola.stop();
    actions.fecharBorracha.play();
    actions.abrirBorracha.stop();
    actions.abrirBottom.stop();
    actions.abrirMoldeBorracha.stop();
    actions.abrirMoldeDireita.stop();
    actions.abrirMoldeEsquerda.stop();
    actions.abrirSapato.stop();
    actions.abrirSola.stop();
    actions.subirStick.stop();
    actions.subirMolde.stop();
    actions.subirMolde1.stop();
    actions.fecharBorracha.clampWhenFinished = true;
    actions.fecharBorracha.setLoop(LoopOnce);
    actions.fecharBottom.play();
    actions.fecharBottom.clampWhenFinished = true;
    actions.fecharBottom.setLoop(LoopOnce);
    actions.fecharMoldeBorracha.play();
    actions.fecharMoldeBorracha.clampWhenFinished = true;
    actions.fecharMoldeBorracha.setLoop(LoopOnce);
    actions.fecharMoldeDireita.play();
    actions.fecharMoldeDireita.clampWhenFinished = true;
    actions.fecharMoldeDireita.setLoop(LoopOnce);
    actions.fecharMoldeEsquerda.play();
    actions.fecharMoldeEsquerda.clampWhenFinished = true;
    actions.fecharMoldeEsquerda.setLoop(LoopOnce);
    actions.fecharSapato.play();
    actions.fecharSapato.clampWhenFinished = true;
    actions.fecharSapato.setLoop(LoopOnce);
    actions.fecharSola.play();
    actions.fecharSola.clampWhenFinished = true;
    actions.fecharSola.setLoop(LoopOnce);
    actions.descerMoldejunto.play();
    actions.descerMoldejunto.clampWhenFinished = true;
    actions.descerMoldejunto.setLoop(LoopOnce);
    actions.descerMoldejunto1.play();
    actions.descerMoldejunto1.clampWhenFinished = true;
    actions.descerMoldejunto1.setLoop(LoopOnce);
    actions.descerStick.play();
    actions.descerStick.clampWhenFinished = true;
    actions.descerStick.setLoop(LoopOnce);
  };

  const playAnimAbrirMolde = () => {
    setVisibleMolde(true);
    setVisibleCalcanhar(true);
    actions.explodirBorracha.stop();
    actions.explodirSapato.stop();
    actions.explodirSola.stop();
    actions.fecharBorracha.stop();
    actions.fecharBottom.stop();
    actions.fecharMoldeBorracha.stop();
    actions.fecharMoldeDireita.stop();
    actions.fecharMoldeEsquerda.stop();
    actions.fecharSapato.stop();
    actions.fecharSola.stop();
    actions.descerMoldejunto.stop();
    actions.descerMoldejunto1.stop();
    actions.descerStick.stop();
    actions.abrirBorracha.play();
    actions.abrirBorracha.clampWhenFinished = true;
    actions.abrirBorracha.setLoop(LoopOnce);
    actions.abrirBottom.play();
    actions.abrirBottom.clampWhenFinished = true;
    actions.abrirBottom.setLoop(LoopOnce);
    actions.abrirMoldeBorracha.play();
    actions.abrirMoldeBorracha.clampWhenFinished = true;
    actions.abrirMoldeBorracha.setLoop(LoopOnce);
    actions.abrirMoldeDireita.play();
    actions.abrirMoldeDireita.clampWhenFinished = true;
    actions.abrirMoldeDireita.setLoop(LoopOnce);
    actions.abrirMoldeEsquerda.play();
    actions.abrirMoldeEsquerda.clampWhenFinished = true;
    actions.abrirMoldeEsquerda.setLoop(LoopOnce);
    actions.abrirSapato.play();
    actions.abrirSapato.clampWhenFinished = true;
    actions.abrirSapato.setLoop(LoopOnce);
    actions.abrirSola.play();
    actions.abrirSola.clampWhenFinished = true;
    actions.abrirSola.setLoop(LoopOnce);
    actions.subirStick.play();
    actions.subirStick.clampWhenFinished = true;
    actions.subirStick.setLoop(LoopOnce);
    actions.subirMolde.play();
    actions.subirMolde.clampWhenFinished = true;
    actions.subirMolde.setLoop(LoopOnce);
    actions.subirMolde1.play();
    actions.subirMolde1.clampWhenFinished = true;
    actions.subirMolde1.setLoop(LoopOnce);
  };

  return (
    <Center>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <mesh
            name="Borracha"
            castShadow
            receiveShadow
            geometry={nodes.Borracha.geometry}
            material={materials["Sola03.002"]}
            position={[-0.22243625, 0.43509191, 1.02307212]}
            scale={[17.34339523, 17.67790222, 17.67790222]}
          />
          <mesh
            name="Sola"
            castShadow
            receiveShadow
            geometry={nodes.Sola.geometry}
            material={materials["Sola01.002"]}
            position={[-0.21450818, -0.19812369, -0.84806722]}
            scale={17.42144012}
          />
          <mesh
            name="Bottom"
            castShadow
            receiveShadow
            geometry={nodes.Bottom.geometry}
            material={materials["Sola02.002"]}
            position={[-0.19677049, 0.23696366, -0.82546955]}
            scale={[17.93000031, 17.57999992, 17.57999992]}
          />
          <mesh
            name="Carimbo"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.Carimbo.geometry}
            material={materials["Metal.001"]}
            position={[-0.17655531, -2.60983562, 0.51079321]}
            scale={0.17581025}
          />
          <mesh
            name="MoldeEsquerdo"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.MoldeEsquerdo.geometry}
            material={materials["Metal.001"]}
            position={[-1.27727365, -2.79792118, -0.96742994]}
            scale={0.18061072}
          />
          <mesh
            name="MoldeDireito"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.MoldeDireito.geometry}
            material={materials["Metal.001"]}
            position={[0.9114058, -2.79602361, -0.83460718]}
            scale={0.18061072}
          />
          <mesh
            name="MoldeTras"
            visible={visibleMolde ? true : false}
            castShadow
            receiveShadow
            geometry={nodes.MoldeTras.geometry}
            material={materials["Metal.001"]}
            position={[-0.15885381, -0.143528, 3.80962563]}
            scale={0.18061072}
          />
          <group
            visible={visibleMolde ? true : false}
            name="moldeStick"
            position={[-1.19842935, 0.04322009, -0.47455412]}
            rotation={[0.07437607, 0, 0]}
            scale={[13.66537476, 13.66537666, 13.66537666]}
          >
            <mesh
              name="moldeStick_1"
              castShadow
              receiveShadow
              geometry={nodes.moldeStick_1.geometry}
              material={materials["#787878"]}
            />
            <mesh
              name="moldeStick_2"
              castShadow
              receiveShadow
              geometry={nodes.moldeStick_2.geometry}
              material={materials["blue backsefdfg.002"]}
            />
            <mesh
              name="moldeStick_3"
              castShadow
              receiveShadow
              geometry={nodes.moldeStick_3.geometry}
              material={materials["blue backsef.004"]}
            />
          </group>
          <group
            name="calcanhar"
            visible={visibleMolde ? true : false}
            position={[-1.19842935, 0.04322009, -0.47455412]}
            rotation={[-0.0128904, 0, 0]}
            scale={13.66537476}
          >
            <mesh
              name="calcanhar_1"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_1.geometry}
              material={materials["Material #506"]}
            />
            <mesh
              name="calcanhar_2"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_2.geometry}
              material={materials["Material #59"]}
            />
            <mesh
              name="calcanhar_3"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_3.geometry}
              material={materials["#D2D2D2"]}
            />
            <mesh
              name="calcanhar_4"
              castShadow
              receiveShadow
              geometry={nodes.calcanhar_4.geometry}
              material={materials["blue backsefdfg.002"]}
            />
          </group>
          <group
            name="molde"
            visible={visibleMolde ? true : false}
            position={[-1.19842935, 0.04322009, -0.47455412]}
            rotation={[-0.0128904, 0, 0]}
            scale={13.66537476}
          >
            <mesh
              name="molde_1"
              castShadow
              receiveShadow
              geometry={nodes.molde_1.geometry}
              material={materials["#808080"]}
            />
            <mesh
              name="molde_2"
              castShadow
              receiveShadow
              geometry={nodes.molde_2.geometry}
              material={materials["Material #503.001"]}
            />
            <mesh
              name="molde_3"
              castShadow
              receiveShadow
              geometry={nodes.molde_3.geometry}
              material={materials["Material #521"]}
            />
            <mesh
              name="molde_4"
              castShadow
              receiveShadow
              geometry={nodes.molde_4.geometry}
              material={materials["Material #523"]}
            />
            <mesh
              name="molde_5"
              castShadow
              receiveShadow
              geometry={nodes.molde_5.geometry}
              material={materials["Material #504"]}
            />
            <mesh
              name="molde_6"
              castShadow
              receiveShadow
              geometry={nodes.molde_6.geometry}
              material={materials["blue backsef.004"]}
            />
            <mesh
              name="molde_7"
              castShadow
              receiveShadow
              geometry={nodes.molde_7.geometry}
              material={materials["blue backsefdfg.002"]}
            />
            <mesh
              name="molde_8"
              castShadow
              receiveShadow
              geometry={nodes.molde_8.geometry}
              material={materials["blue backsefdfgef"]}
            />
            <mesh
              name="molde_9"
              castShadow
              receiveShadow
              geometry={nodes.molde_9.geometry}
              material={materials["#808080.001"]}
            />
            <mesh
              name="molde_10"
              castShadow
              receiveShadow
              geometry={nodes.molde_10.geometry}
              material={materials["Metal.002"]}
            />
          </group>
          <group
            name="Sapato"
            position={[-0.15245104, 0.41271371, -2.62903595]}
            scale={17.44918251}
          >
            <mesh
              name="Quarter_vp002001"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001.geometry}
              material={materials["leather02.002"]}
            />
            <mesh
              name="Quarter_vp002001_1"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_1.geometry}
              material={materials["leather07.002"]}
            />
            <mesh
              name="Quarter_vp002001_2"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_2.geometry}
              material={materials["leather03.002"]}
            />
            <mesh
              name="Quarter_vp002001_3"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_3.geometry}
              material={materials["Laces.002"]}
            />
            <mesh
              name="Quarter_vp002001_4"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_4.geometry}
              material={materials["blinn1.003"]}
            />
            <mesh
              name="Quarter_vp002001_5"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_5.geometry}
              material={materials["Sola02.003"]}
            />
            <mesh
              name="Quarter_vp002001_6"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_6.geometry}
              material={materials["leather.002"]}
            />
            <mesh
              name="Quarter_vp002001_7"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_7.geometry}
              material={materials["leather06.002"]}
            />
            <mesh
              name="Quarter_vp002001_8"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_8.geometry}
              material={materials["blinn1.001"]}
            />
            <mesh
              name="Quarter_vp002001_9"
              castShadow
              receiveShadow
              geometry={nodes.Quarter_vp002001_9.geometry}
              material={materials["efwe.002"]}
            />
          </group>
        </group>
      </group>
    </Center>
  );
}

useGLTF.preload("/modelo02.glb");

function Modelo2() {
  const [fecharMolde, setFecharMolde] = useState(false);
  const [abrirMolde, setAbrirMolde] = useState(false);
  const [sapatoNormal, setSapatoNormal] = useState(true);
  const [sapatoExplode, setSapatoExplode] = useState(false);

  const playFecharMolde = () => {
    setFecharMolde(true);
    setAbrirMolde(false);
    setSapatoNormal(false);
    setSapatoExplode(false);
  };

  const playAbrirMolde = () => {
    setAbrirMolde(true);
    setFecharMolde(false);
    setSapatoNormal(false);
    setSapatoExplode(false);
  };

  const playSapatoNormal = () => {
    setSapatoNormal(true);
    setFecharMolde(false);
    setAbrirMolde(false);
    setSapatoExplode(false);
  };

  const playSapatoExplode = () => {
    setSapatoExplode(true);
    setSapatoNormal(false);
    setFecharMolde(false);
    setAbrirMolde(false);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f8f8",
        overflow: "hidden",
      }}
    >
      <div className="controlsMain">
        <div className="controls">
          <Button
            variant="contained"
            onClick={playSapatoNormal}
            disabled={sapatoNormal ? true : false}
            className="btnAnimation"
          >
            Shoe
          </Button>
          <Button
            variant="contained"
            onClick={playSapatoExplode}
            disabled={sapatoExplode ? true : false}
            className="btnAnimation"
          >
            Explode Shoe
          </Button>
          <Button
            variant="contained"
            onClick={playFecharMolde}
            disabled={fecharMolde ? true : false}
            className="btnAnimation"
          >
            Close Mold
          </Button>
          <Button
            variant="contained"
            onClick={playAbrirMolde}
            disabled={abrirMolde ? true : false}
            className="btnAnimation"
          >
            Open Mold
          </Button>
        </div>
      </div>
      <div className="modelDiv">
        <Canvas>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} />

            <ModelExplode
              fecharMolde={fecharMolde}
              abrirMolde={abrirMolde}
              sapatoNormal={sapatoNormal}
              sapatoExplode={sapatoExplode}
              scale={0.3}
            />

            <Environment files={"hdr2.hdr"} />
            <ViewCube />
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <Button
          variant="contained"
          onClick={playSapatoNormal}
          disabled={sapatoNormal ? true : false}
          className="btnAnimation"
        >
          Shoe
        </Button>
        <Button
          variant="contained"
          onClick={playSapatoExplode}
          disabled={sapatoExplode ? true : false}
          className="btnAnimation"
        >
          Explode Shoe
        </Button>
        <Button
          variant="contained"
          onClick={playFecharMolde}
          disabled={fecharMolde ? true : false}
          className="btnAnimation"
        >
          Close Mold
        </Button>
        <Button
          variant="contained"
          onClick={playAbrirMolde}
          disabled={abrirMolde ? true : false}
          className="btnAnimation"
        >
          Open Mold
        </Button>
      </div>
    </div>
  );
}

export default Modelo2;
